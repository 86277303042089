<template>
    <v-container fluid>
        <v-row row wrap>
            <v-col cols="12" md="6" class="mt-2">
                <v-select :items="audiences" v-model="audience"
                outlined dense hide-details
                label="Send To"
                ></v-select>
            </v-col>

            <v-col cols="4" md="2" class="text-center">
                 <v-checkbox dense
                    v-model="personalized" hide-details
                    label="Personalize Greeting"
                ></v-checkbox>
            </v-col>
            <v-col cols="4" md="2" class="text-center">
                <v-checkbox dense :disabled="isProductRelease"
                    v-model="link" hide-details
                    label="Include Login Link"
                ></v-checkbox>
            </v-col>
            <v-col cols="4" md="2" class="text-center">
                <v-checkbox dense
                    v-model="ccEmployees" hide-details
                    label="CC Employees"
                ></v-checkbox>
            </v-col>


            <v-col cols="12"  mt-1>
                <v-tabs v-model="active" color="purple">

                    <v-tab>Product Release</v-tab>
                    <v-tab-item>
                        <v-col ma-3>
                            <v-select :items="products" v-model="selected"
                                label="Select a product..." item-value="id" item-text="name"
                                return-object
                            ></v-select>
                        </v-col>
                        <v-card>
                            <v-col ma-3>
                                <template v-if="selected!=null">
                                    <v-card-text><strong>SUBJECT: </strong>{{productReleaseSubject}}</v-card-text>
                                    <v-card-text>{{greeting}}</v-card-text>
                                    <v-card-text>
                                        <span v-html="productReleaseMessage.html"></span>
                                    </v-card-text>
                                    <v-card-text>Cheers,<br><br>WHBC</v-card-text>
                                </template>
                            </v-col>
                        </v-card>
                    </v-tab-item>
                    
                    <v-tab>Custom Message</v-tab>
                    <v-tab-item>
                        <v-card outlined >
                            <v-col ma-3>
                                <v-text-field dense outlined name="input-7-4" label="Enter subject here"
                                    v-model="customSubject"
                                ></v-text-field>
                                <v-card-text>{{greeting}}</v-card-text>
                                <vue-editor 
                                    v-model="customMessage" 
                                    use-custom-image-handler 
                                    @image-added="handleImageAdded"
                                    />
                          <!--      <v-textarea outlined dense name="input-7-4" label="Enter message body here"
                                    v-model="customMessage"
                                ></v-textarea>
                                -->
                                <v-card-text v-if="link">
                                    <span v-html="loginLink.html"></span>
                                </v-card-text>
                                <v-card-text>Cheers,<br><br>WHBC</v-card-text>
                            </v-col>
                        </v-card>
                    </v-tab-item>

                </v-tabs>
                <v-col cols="12" class="text-center" mt-1>
                <v-btn color="primary" @click="sendTestEmail" :loading="loading" :disabled="!readyToSend">Send Email</v-btn>
            </v-col>
            </v-col>
            
            <v-dialog v-model="sentToAdmins" persistent max-width="500">
                <v-card>
                  <v-card-title primary-title>
                    <div class="headline mb-0">Test email sent!</div>

                  </v-card-title>
                  <v-card-subtitle class="mt-3">
                    A test email has been sent to <strong>you only</strong>. Check that the email came through as planned, then click SEND IT to send to the intended audience.</div>
                 </v-card-subtitle>

                  <v-card-actions>
                    <v-btn outlined color="error" @click="sendToAudience" :loading="loading">Send It</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="primary" @click="sentToAdmins = false" :loading="loading">Cancel and Edit Email</v-btn>
                  </v-card-actions>
                </v-card>
            </v-dialog>

        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations} from 'vuex'
import { VueEditor } from "vue2-editor";
import AllMixins from '@/mixins/AllMixins'
import api from '@/store/api'
import moment from 'moment'
export default {
    data() {
        return {
            active: null,
            sentToAdmins: false,
            customSubject: '',
            customMessage: '',
            selected: null,
            personalized: false,
            link: true,
            ccEmployees: false,
       //     loading: false,
            //audience is in AllMixins
        }
    },
    mounted () {
        this.getAvailableProducts()
        this.getAllUsers()
    },
    mixins: [AllMixins],
    components: {
        VueEditor
    },
    computed: {
        ...mapGetters ([
            'userInfo'
        ]),
        readyToSend(){
            return this.audience != undefined && this.audience != ""
        },
        products () {
            let products = this.$store.state.products
            if(products){
                let filtered = []
                products.forEach(product => {
                    if(product.status == "Approved")
                        filtered.push(product)
                })
                return filtered
            }
            else{
                return []
            }
        },
        customizedMessage () {
            let body = {}
            body.html = this.customMessage
            if(this.link) //add login link at footer
                body.html += this.loginLink.html
            body.text = this.convertHtmlToText(body.html)
            return body
        },
        productReleaseSubject () {
            if(!this.selected)
                return null
            else
                return "Now Available for Pre-Order: " + this.selected.name.toUpperCase()
        },
        productReleaseMessage () {
            if(!this.selected)
                return {html: ""}
            let body = {}
            body.html = "Introducing <strong>" + this.selected.name.toUpperCase() + "</strong>, " + this.chooseArticleForNumber(this.selected.abv) + " " + this.selected.abv + "% " + this.selected.style + "...<br><br>"
            body.html += this.selected.description + "<br><br>"
            //body.html += "<img src=\"" + this.selected.art.thumbnails.large.url + "\" width=\"300\" alt=\"" + this.selected.name + "\"><br><br>"
            body.html += "<img src=\"" + this.selected.imageLarge + "\" width=\"300\" alt=\"" + this.selected.name + "\"><br><br>"
            body.html += "$" + this.selected.price + " per " + this.selected.size + ", limit " + this.selected.limit + " per person <br><br>"
            body.html += "<strong>PRE-ORDER DEADLINE</strong>: " + this.formatDate(this.selected.orderEnd) + "<br><br>"
            body.html += "<strong>PICKUP WINDOW</strong>: " + this.formatDate(this.selected.pickupStart) + " until " + this.formatDate(this.selected.pickupEnd) + "<br><br>"
            if(this.selected.fulfillments && this.selected.fulfillments.length > 0){
                body.html += "<strong>PICKUP LOCATIONS</strong>: "
                this.selected.fulfillments.forEach(fulfillment => {
                    body.html += fulfillment.location
                    if(fulfillment.location != this.selected.fulfillments[this.selected.fulfillments.length-1].location)
                        body.html += ", "
                })
                if(this.selected.fulfillments.length > 1)
                    body.html += " (choose one at checkout)"
                body.html += "<br><br>"
            }
            body.html += "Please <a href=\"https://saguaro.botls.app\">login to your account</a> to place a pre-order"

            body.text = "Introducing " + this.selected.name.toUpperCase() + ", " + this.chooseArticleForNumber(this.selected.abv) + " " + this.selected.abv + "% " + this.selected.style + "...\n\n"
            body.text += this.selected.description + "\n\n"
            body.text += "$" + this.selected.price + " per " + this.selected.size + ", limit " + this.selected.limit + " per person \n\n"
            body.text += "PRE-ORDER DEADLINE: " + this.formatDate(this.selected.orderEnd) + "\n\n"
            body.text += "PICKUP WINDOW: " + this.formatDate(this.selected.pickupStart) + " until " + this.formatDate(this.selected.pickupEnd) + "\n\n"
            body.text += "Please login to your account to place a pre-order at https://saguaro.botls.app"

            return body
        },
        greeting () {
            let str = "Greetings Members,"
            if(this.audience == "keystone")
                str = "Hello Keystone Members!"
            if(this.personalized)
                str = "Hi [member name],"
            return str
        },
        loginLink(){
            /**
             * Returns the login link for the email footer
             * Two lines breaks are required before the link
             */
            let foot = {}
            if(this.isProductRelease){
                foot.html = "<a href=\"https://saguaro.botls.app\">Login to your account</a> to place a pre-order"
                foot.text = "Login to your account at https://saguaro.botls.app to place a pre-order"
            }
            else{
                foot.html = "Login to your account <a href=\"https://saguaro.botls.app\">here</a>"
                foot.text = "Login to your account at https://saguaro.botls.app"
            }
            return foot
        },
        isProductRelease(){
            return (this.active == 0)
        }
    },
    methods: {
        ...mapMutations ([
     //       'userInfo'
        ]),
        async handleImageAdded(file, Editor, cursorLocation, resetUploader) {

            const storageRef = global.FIREBASE.storage.ref(`images/${file.name}`)


            // Create a storage reference
            //const storage = getStorage();

            //const storageRef = ref(storage, `images/${file.name}`)

            // Upload the file
            const snapshot = await storageRef.put(file)

            // Get the public URL of the file
            const publicUrl = await snapshot.ref.getDownloadURL()
            Editor.insertEmbed(cursorLocation, 'image', publicUrl)
            resetUploader()      
        },
        formatDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").format("M/D/YYYY ha")
        },
        chooseArticleForNumber(num) {
            // Determine the article based on the first digit
            const vowelStartingNumbers = [8, 11, 18];
            const article = vowelStartingNumbers.includes(num) ? 'an' : 'a';
            return article;
        },
        convertHtmlToText (html) {
            let text = html.replace(/<br>/g, "\n")
            text = text.replace(/<strong>/g, "")
            text = text.replace(/<\/strong>/g, "")
            text = text.replace(/<a href="(.*)">(.*)<\/a>/g, "$2 ($1)")
            return text
        },
        async sendTestEmail () {
            this.$store.commit('setLoading', true)
            let testUser = this.userInfo
            let subject = (this.isProductRelease ? this.productReleaseSubject: this.customSubject)
            let body = (this.isProductRelease ? this.productReleaseMessage: this.customizedMessage) //body contains html and text versions
            let self = this

            var functionsCall = global.FIREBASE.functions.httpsCallable('sendTestEmail')
            let result = await functionsCall(
                {
                    testUser: testUser, 
                    subject: subject, 
                    greeting: this.greeting,
                    body: body, 
                    personalized: self.personalized
                }
            )
            if(result.data.status == 'success'){
                this.$store.commit('setLoading', false)
                this.sentToAdmins = true
            }
            else{
                console.log(result.data.error)
                this.loading = false
            }
        },
        async sendToAudience () { //this function is only accessible after sending to admin, enforced by dialog popup sequence
            this.loading = true
            let uid = this.$store.state.user.uid
            let subject = (this.isProductRelease ? this.productReleaseSubject: this.customSubject)
            let body = (this.isProductRelease ? this.productReleaseMessage: this.customizedMessage) //body contains html and text versions

            var functionsCall = global.FIREBASE.functions.httpsCallable('emailAudience')
            let result = await functionsCall(
                {   uid: uid, 
                    subject: subject, 
                    greeting: this.greeting,
                    body: body, 
                    personalized: this.personalized, 
                    audience: this.audience,
                    ccEmployees: this.ccEmployees
                }
            )
            if(result.data.status == "success"){
                this.loading = false
                this.sentToAdmins = false //this will hide the send it dialog
                this.reset()
            }
            else{
                console.log(result.data.error)
                this.loading = false
            }

        },
        reset () {
            this.active = 0
            this.sentToAdmins = false
            this.customSubject =  ''
            this.customMessage = ''
            this.selected = null
        }
    },
}
</script>